<template>
  <div class="course-list-wrapper my-4" style="min-height: 660px;">
    <div class="subject-list flex flex-wrap items-center mt-4 md:mx-0 mx-3">
      <span class="md:mb-3 mb-2">学科：</span>
      <el-button
        class="md:mb-3 mb-2"
        v-for="item of subjectList"
        :key="item.id"
        :plain="subjectAction(item.id)"
        size="small"
        type="warning"
        @click="selectSubject(item.id)"
      >{{ item.name }}</el-button>
    </div>
    <div class="level-list flex flex-wrap items-center md:mx-0 mx-3">
      <span class="md:mb-3 mb-2">等级：</span>
      <el-button
        class="md:mb-3 mb-2"
        v-for="item of levelList"
        :key="item.id"
        :plain="levelAction(item.name)"
        size="small"
        type="primary"
        @click="selectLevel(item.name)"
      >{{ item.name }}</el-button>
    </div>
    <div v-if="list.length > 0">
      <div class="flex flex-wrap mb-4">
        <div class="md:w-1/4 w-full cursor-pointer"
            :class="[index % 4 === 0? 'md:pb-3 md:px-0 pb-3':'md:pl-3 md:pr-0 md:pb-3 pb-3']"
            v-for="(item, index) of list"
            :key="item.id"
            @click="courseDetail(item)"
        >
          <el-card
            :body-style="{ padding: '0px' }"
            shadow="hover"
          >
            <div class="flex justify-center overflow-hidden" style="height: 184px;">
              <img class="h-full" :src="item.cover" alt="course">
            </div>
            <div class="p-3 text-left">
              <p class="text-lg">{{ item.name }}</p>
              <p class="py-1 truncate text-sm text-gray-500" :title="item.brief">{{ item.brief }}</p>
              <el-tag type="warning" v-if="item.subject" class="mr-2 my-1">{{ item.subject.name }}</el-tag>
              <el-tag v-if="item.cate" class="my-1">{{ item.cate.name }}</el-tag>
            </div>
          </el-card>
        </div>
      </div>
      <el-pagination
        class="my-4 md:hidden"
        background
        small
        layout="prev, pager, next"
        :page-size="query.num"
        :total="courseTotal"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <el-pagination
        class="my-4 md:block hidden"
        background
        layout="prev, pager, next"
        :page-size="query.num"
        :total="courseTotal"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-empty v-else description="暂时没有课程"></el-empty>
  </div>
</template>

<script>

import { list } from '@/api/course'
// import checkPermission from '@/utils/permission'

export default {
  name: 'CourseList',
  data() {
    return {
      list: [],
      subjectList: [
        { id: 0, name: '全部' },
        { id: 1, name: 'Scratch' },
        { id: 4, name: 'Python' },
        { id: 5, name: 'C++' },
        { id: 6, name: '机器人技术' },
        { id: 7, name: '3D 创意' },
        { id: 11, name: '无人机技术' },
      ],
      query: {
        subject: 0,
        level: '全部',
        page: 1,
        num: 12
      },
      levelList: [
        { id: 0, name: '全部' },
        { id: 1, name: '一级' },
        { id: 2, name: '二级' },
        { id: 3, name: '三级' },
        { id: 4, name: '四级' },
      ],
      courseTotal: 0,
      currentPage: 1
    }
  },
  mounted() {
    // this.subjectInit()
    this.init()
  },
  methods: {
    subjectAction(id) {
      if (this.query.subject === id) {
        return !true
      }
      return !false
    },
    levelAction(val) {
      if (this.query.level === val) {
        return !true
      }
      return !false
    },
    // subjectInit() {
    //   subjectList().then(res => {
    //     this.subjectList = res.data
    //   })
    // },
    init() {
      list(this.query).then(res => {
        this.list = res.data.list
        this.courseTotal = res.data.count
      })
    },
    selectSubject(id) {
      this.query.subject = id
      this.init()
    },
    selectLevel(val) {
      this.query.level = val
      this.init()
    },
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    courseDetail(row) {
      // VIP 专享
      // if (!checkPermission(['vip'])) {
      //   this.$message({
      //     type: 'warning',
      //     message: '抱歉，您没有权限。开通 VIP 即可学习所有课程！',
      //     duration: 2000
      //   })
      //   return false
      // }
      this.$router.push({ path: '/course/detail', query: { id: row.id }})
    }
  }
}
</script>