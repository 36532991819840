import request from '@/utils/request'
const baseURL = '/exam.course'

/**
 * 课程列表
 * @param {*} params 
 * @returns 
 */
export function list(params) {
  return request({
    url: `${baseURL}/list`,
    method: 'get',
    params
  })
}
/**
 * 学科列表
 * @param {*} params 
 * @returns 
 */
export function subjectList(params) {
  return request({
    url: `${baseURL}/subjectList`,
    method: 'get',
    params
  })
}
/**
 * 课程详情
 * @param {*} params 
 * @returns 
 */
export function detail(params) {
  return request({
    url: `${baseURL}/detail`,
    method: 'get',
    params
  })
}